.margin-left::first-letter {
    margin-left: 1.5em;
}

.margin-left {
    text-align: scroll;
    line-height: 1.4;
}

img[alt="Paelladish"] {
    height: 100% !important;
    object-fit: cover !important;
}

@keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}

.blink-text::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
    animation: blink 1s infinite;
  }

  .backdrop {
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .backdrop::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    backdrop-filter: blur(10px);
  }